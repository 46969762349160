export function getWordsScrolled(scrollDepth: number, totalWordsInDocument?: number) {
  return totalWordsInDocument ? totalWordsInDocument * scrollDepth : 0;
}

export function getWordsScrolledDelta(wordsScrolled: number, startScrollValue: number) {
  return wordsScrolled - startScrollValue;
}

export function getIsScrolledToBottom(scrollDepth: number) {
  return scrollDepth === 1;
}

// Readers typically focus not at the very top of the screen, so we add a margin from the top.
// NOTE: On web, this is the maximum supported, as otherwise the scroll depth will never reach 1.
const VIEWPORT_THRESHOLD_PERCENT = 0.1;

type ProgressParams = {
  contentElement: HTMLElement | undefined;
  centeredElement?: HTMLElement; // Optional override for content top position
};

/**
 * Calculates how far the content has been scrolled as a value between 0 and 1.
 *
 * This function accepts either a top value or falls back to the top of the content element.
 */
function calculateContentProgress({
  contentElement,
  centeredElement,
}: ProgressParams): number {
  if (!contentElement) {
    return 0;
  }

  const viewportHeight = window.innerHeight;

  const contentRect = contentElement.getBoundingClientRect();
  const viewportThreshold = viewportHeight * VIEWPORT_THRESHOLD_PERCENT;

   // Calculate how much content has been scrolled above the threshold
  // We add viewportThreshold to contentTop because we want to count content
  // as "read" when it moves above the viewport
  const contentTop = centeredElement ? contentRect.top - centeredElement.getBoundingClientRect().top : contentRect.top;
  const contentAboveThreshold = Math.max(0, -(contentTop + viewportThreshold));

  const effectiveViewportHeight = viewportHeight - viewportThreshold;
  const totalScrollableHeight = contentRect.height - effectiveViewportHeight;

  // The px values cancel out, so we receive a device independent value
  const progress = contentAboveThreshold / totalScrollableHeight;

  return Number(Math.min(1, Math.max(0, progress)).toFixed(8));
}

/**
 * Calculates how far the document is scrolled as a percentage (0-1).
 */
export function getScrollDepth(contentElement: HTMLElement | undefined): number {
  return calculateContentProgress({ contentElement });
}

/**
 * Calculates the current reading position within the document based on the provided scroll value.
 */
export function getReadingProgress(contentElement: HTMLElement | undefined, serializedPositionElement: HTMLElement | undefined): number {
  return calculateContentProgress({ contentElement, centeredElement: serializedPositionElement });
}
