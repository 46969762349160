import { ContentFrameEventMessageArgumentMap } from '../../foreground/types/events';
import PortalGate from '../../PortalGate';
import { CanvasImageHighlight } from '../../quoteshots/CanvasImageGenerator';
import { AnyDocument, LenientReadingPosition, TtsPosition } from '../../types';
import type { ChunkSanitizationOptions, WebviewChunks } from '../../types/chunkedDocuments';
import { QuoteshotFont, ThemeVariant } from '../../types/quoteshots';
import type { DocumentTag } from '../../types/tags';
import { Theme } from '../css/baseStyles';
import type { ScrollingManager } from './ScrollingManager';

export interface InitContentFrameProps {
  docId: string;
  initialReadPosition: LenientReadingPosition;
  initialScrollPosition: LenientReadingPosition;
  initialTtsPosition: TtsPosition | undefined;
  os: 'ios' | 'android';
  tags: DocumentTag[];
  title: string;
  category: AnyDocument['category'];
  userEmail: string;
  isPaginatedMode: boolean;
  deviceWidth: number;
  currentTheme: Theme;
  isDigestView: boolean;
  instanceToken: string;
}

export interface MobileContentFrameWindow extends Window {
  theme: Theme;
  s?: ScrollingManager;
  documentLoaded?: boolean;
  deviceWidth?: number;
  isAutoHighlightingEnabled?: boolean;
  initContentFrame?: (props: InitContentFrameProps) => void;
  initCanvasWebview?: () => void;
  osType: 'ios' | 'android';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portalGateToForeground: PortalGate<any, ContentFrameEventMessageArgumentMap>;

  // Methods
  titleClicked: () => void;
  ttsButtonClicked: () => void;
  reloadImagesThatFailedToLoad: () => void;
  drawHighlightOnCanvas?: (
    highlight: CanvasImageHighlight,
    theme: ThemeVariant,
    width: number,
    aspectRatio: number,
    darkMode: boolean,
    currentFont: QuoteshotFont,
  ) => Promise<void>;
  onFaviconError: () => void;
  onFaviconLoaded: () => void;
  onHeaderImageLoaded: () => void;
  onHeaderImageError: () => void;
  onSecondaryHeaderImageLoaded: () => void;
  onSecondaryHeaderImageError: () => void;
  initContent: (chunks: WebviewChunks, sanitizationOptions: ChunkSanitizationOptions) => void;
  summaryContainerToggled: () => void;
  // for easier debugging
  qs?: typeof document.querySelector;
  qsa?: typeof document.querySelectorAll;

}

export const CLICKABLE_TAGS = new Set(['A', 'FIGURE', 'IMG', 'VIDEO', 'RW-HIGHLIGHT', 'SVG']);
export const CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS = new Set(['A', 'RW-HIGHLIGHT']);

export const PAGINATION_DOCUMENT_TOP_MARGIN = 1000;
